<template>
<!-- <vx-card class="mb-base" :title="title"> -->
    <div slot="no-body">
      <div v-show="isMounted" class="post-media-container">
        <ul class="flex post-media-list">
          <li
            class="post-media m-1 w-full">
            <video
              ref="videoPlayer"
              :style="`width: ${cWidth};height: ${cHeight};`"
              class="video-js media-video-player"
              :data-setup="JSON.stringify(dataSetup)"
            />        
          </li>
        </ul>
      </div>
    </div>
  <!-- </vx-card> -->
</template>

<script>
import videojs from 'video.js';
require('videojs-youtube');
import 'video.js/dist/video-js.css'

export default {
  name: 'youtube-video-widget',
  props: {
    media: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    cWidth: {
      type: String,
      required: false,
      default: ""
    },
    cHeight: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      player: null,
      isMounted:false,
      playerOptions:{
        controls: true,
        height:"360"
      },
      dataSetup: {
        "techOrder":["youtube"], 
        "sources":this.media.sources,
        "poster":this.media.poster,
        "youtube": { "iv_load_policy": 1 }
      }
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.playerOptions);
    this.isMounted = true;
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
<style>
  /* #vjs_video_3 {
    width: 24rem;
    height: 15rem;
  } */
</style>